import { Component } from '@angular/core';
import { SplashScreenService } from './core/splash-screen.service';
import {Router} from '@angular/router';

@Component({
  selector: 'elastic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private splashScreenService: SplashScreenService, private router:Router) {
    
    if(window.localStorage.getItem('token') === undefined){
      this.router.navigate(['auth/login']);
    }
    
  }

}
