import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';

import { InvoiceComponent } from './tables/invoice/invoice.component';
import { ProductsComponent } from './products/products.component';


@NgModule({
  imports: [
    CommonModule,
    AuthModule

  ],
  declarations: [InvoiceComponent, ProductsComponent]
})
export class PagesModule { }
