import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartbadgeService {
  @Output() fire: EventEmitter<any> = new EventEmitter();
  constructor() {
 //   console.log('shared service started');
  }
  change(nCart: number) {
   // console.log('change started');
     this.fire.emit(nCart);
   }

   getEmittedValue() {
     return this.fire;
   }
}
