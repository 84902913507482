import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/api/http.service';
import { ROUTE_TRANSITION } from '../../../app.animation';

@Component({
  selector: 'elastic-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class ForgotPasswordComponent implements OnInit {

  email: string;

  constructor(
    private router: Router,public http: HttpService  ) { }

  ngOnInit() {
  }

  send() {
    this.http.getRecovery(this.email)
      .subscribe((res: {success:boolean}) => {
        if(res.success){
          window.alert("Su clave fué enviado a su correo");
          this.router.navigate(['/auth/login']);
        }
      });
    
  }

   

}
