import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LIST_FADE_ANIMATION } from '../../utils/list.animation';
import { Location } from '@angular/common';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Cart } from 'src/app/ctrl/cart';
import { CartbadgeService } from 'src/app/services/cartbadge.service';
import { Item } from 'src/app/class/item';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/api/http.service';
import { User } from 'src/app/class/user';

@Component({
  selector: 'elastic-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION]
})
export class ToolbarNotificationsComponent implements OnInit {

  isOpen: boolean;
  notifications: any[];
  demoTriggers = 0;
  visible;
  ncart: number;
  cart: Cart;
  subscription: any;
  cb: CartbadgeService;
  items: Item[];
  nCart: number;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private storage: LocalStorage,

    cb: CartbadgeService,
    private http: HttpService
  ) {


    this.cart = new Cart(this.storage, this.http);
    this.cb = cb;
    this.visible = true;

  }

  ngOnInit() {

    this.notifications = [
     /* {
        icon: 'notifications',
        name: 'This is a notification',
        time: 'few sec ago',
        read: false,
        colorClass: ''
      },
      {
        icon: 'shopping_basket',
        name: 'User bought your template',
        time: '23 min ago',
        read: false,
        colorClass: 'primary'
      },
      {
        icon: 'eject',
        name: 'Server Crashed',
        time: 'an hour ago',
        read: false,
        colorClass: 'accent'
      },
      {
        icon: 'cached',
        name: 'New user registered',
        time: '6 hours ago',
        read: true,
        colorClass: ''
      },
      {
        icon: 'code',
        name: 'John added you as friend',
        time: 'yesterday',
        read: true,
        colorClass: ''
      }*/
    ];

   // this.getNcart();

    this.subscription = this.cb.getEmittedValue()
      .subscribe(item => {
        console.log(item);
        this.nCart = item;

        this.cd.markForCheck();

      });

  }

  markAsRead(notification) {
    notification.read = true;
  }

  dismiss(notification) {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
    this.triggerDemoNotification();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.triggerDemoNotification();
  }

  onClickOutside() {
    this.isOpen = false;
  }

  goToCart() {

    this.router.navigate(['forms/form-wizard']);

  }

  getNcart() {
return this.nCart;
    
  }

  getSession(){
    this.storage.getItem('items').subscribe((items: Item[]) => {
      this.items = items;
      console.log("notificacio");
      if (this.items === null || this.items === undefined) {

        this.storage.getItem('user').subscribe((user: User) => {

          this.storage.getItem('customer').subscribe((customer: any) => {

if(customer !== null && customer.idcustomer !== null){this.http.getCart(user, customer.idcustomer)
  .subscribe((res: any) => {
    let itemfrom = JSON.parse(atob(res.result));
    if (res.result === "") {
      this.items = [];
      this.nCart = 0;
    } else {
      this.items = [];
      this.nCart = Object.keys(itemfrom).length;
      for (let ix = 0; ix < itemfrom.length; ix++) {
        const item: Item = new Item();
        item.setQty(itemfrom[ix].qty);
        item.setProduct(itemfrom[ix].product);




        if (this.items['i' + item.getProduct().idproduct] === undefined) {
          this.items['i' + item.getProduct().idproduct] = item;
          console.log('i' + item.getProduct().idproduct);
        } else {
          console.log(this.items['i' + item.getProduct().idproduct]);

          this.items['i' + item.getProduct().idproduct].qty = parseInt(this.items['i' + item.getProduct().idproduct].qty) + parseInt(item.qty + '');
        }
      }


    }

    this.storage.setItem('items', this.items).subscribe(() => { });


  });}
            

          });

        });




      } else {
        this.nCart = Object.keys(this.items).length;
      }


      console.log(this.nCart);

    });
  }

  triggerDemoNotification() {
    if (this.demoTriggers === 0) {
      this.demoTriggers++;
/*
      setTimeout(() => {
        this.notifications.unshift({
          icon: 'cached',
          name: 'New user registered',
          time: moment().fromNow(),
          read: false,
          colorClass: '',
        });

        this.cd.markForCheck();
      }, 2000);
    } else if (this.demoTriggers === 1) {
      this.demoTriggers++;

      setTimeout(() => {
        this.notifications.unshift({
          icon: 'shopping_basket',
          name: 'User bought your template',
          time: '23 min ago',
          read: false,
          colorClass: 'primary'
        });

        this.cd.markForCheck();
      }, 2000);*/
    }
  }
}
