import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  @Output() fire: EventEmitter<any> = new EventEmitter();
  constructor() { 
    console.log('search service started');

  }

  change(code: string) {
    console.log('change started');
     this.fire.emit(code);
   }

   getEmittedValue() {
     return this.fire;
   }
}
