import { StoreConfig } from '@ngrx/store';

export class User {
    iduser: number;
    email: string;
    name: string;
    pass: string;
    atribute: string;
    token: string;
    type: string;
    img: string;
    customer?:any[];

    getImg() {

        if (this.img.indexOf('http') ) {
          this.img = '/rest/' + this.img;
        } else {
          this.img = 'assets/img/demo/avatars/noavatar.png';
        }
      }

}
