import { ProductModel } from './product-model';


export class Item {

     product: ProductModel;
     qty: number;

    constructor() {

    }

    set(product: ProductModel, qty: number) {
        this.product = product;
        this.qty = qty;
    }

    getProduct() {
        return this.product;
    }

    getQty() {
        return this.qty;
    }

    setQty(qty: any) {
        this.qty = qty;
    }

    setProduct(product: ProductModel) {
        this.product = product;
      }


      toJSON() {
        return {
          product: this.product, 
          qty: this.qty, 
        }
      }

}
