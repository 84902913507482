import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { getMatIconNameNotFoundError } from '@angular/material/icon';
import { User } from 'src/app/class/user';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/api/http.service';

@Component({
  selector: 'elastic-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {

  isOpen: boolean;
  user: User;
  img: string;
  name: string;
   

  constructor(private storage: LocalStorage,private router: Router, public http: HttpService) {
     
   }

  ngOnInit() {
    this.img = 'assets/img/demo/avatars/noavatar.png';
    this.name = '';
    this.getName();

  }
  getImg() {

    if (this.user.img.indexOf('http') ) {
      this.img = 'https://www.nycofamerica.com/rest/' + this.user.img;
    } else {
      this.img = 'assets/img/demo/avatars/noavatar.png';
    }
  }
  getName() {
    this.storage.getItem('user').subscribe((user: User) => {
      if (user !== null) {
      this.user = user;
      this.name = this.user.name;
      this.getImg(); }else{
        this.storage.clear().subscribe(() => {this.router.navigate(['/auth/login']); });

      }
    });
  }

  ngAfterViewInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logout() {
    this.http.logout(this.user)
        .subscribe((res: any) => {
          this.storage.clear().subscribe(() => {this.router.navigate(['/auth/login']); });


        });

    
    


  }
}
