import { Item } from '../class/item';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Customer } from '../class/customer';
import { CartbadgeService } from '../services/cartbadge.service';
import { User } from '../class/user';
import { HttpService } from '../api/http.service';

export class Cart {

    items: Item[];
    customer: Customer;
    cb: CartbadgeService;

    constructor(private storage: LocalStorage, private http: HttpService) {

    }

    setCustomer() {
        this.customer = this.customer;
        this.getItems();
    }

    getItems(): Item[] {
        if (this.items === null || this.items === undefined) {
            this.getFromStorage();
        }
        return this.items;
    }

    set(item: Item) {
        
        if (this.items === null || this.items === undefined) {
            this.storage.getItem('items').subscribe((items: Item[]) => {
                this.items = items;
              
               
                if (this.items === null || this.items === undefined) {
                    this.items = [];
                }
                if (item.qty < 1) {
                    if (this.items['i' + item.getProduct().idproduct] !== undefined) {
                        delete this.items['i' + item.getProduct().idproduct];
                        // this.storage.setItem('items', this.items).subscribe(() => { });
                    }

                } else if (this.items['i' + item.getProduct().idproduct] === undefined) {
                    this.items['i' + item.getProduct().idproduct] = item;
                } else {
                  

                    this.items['i' + item.getProduct().idproduct].qty = parseInt(this.items['i' + item.getProduct().idproduct].qty) + parseInt(item.qty + '');


                }

                this.storage.setItem('items', this.items).subscribe(() => {


                });

                const keys = Object.keys(this.items);
                const nCart = keys.length;

                this.cb.change(nCart);

                

                let arr = [];

                let _item = this.items;
                keys.forEach(function (ix) {
                    _item[ix].product.galery='';
              _item[ix].product.specification='';
                    arr.push(_item[ix]);
                 
                });




                let it = btoa(JSON.stringify(arr));

                this.storage.getItem('user').subscribe((user: User) => {

                    this.storage.getItem('customer').subscribe((customer: any) => {

                        this.http.setCart(user, customer.idcustomer, it)
                            .subscribe((res: any) => {
                                //this.items = JSON.parse(atob(res.result));

                            });

                    });

                });
            });
        } else {
            if (item.getQty() < 1) {
                if (this.items['i' + item.getProduct().idproduct] !== undefined) {
                    delete this.items['i' + item.getProduct().idproduct];
                    // this.storage.setItem('items', this.items).subscribe(() => { });
                }

            } else if (this.items['i' + item.getProduct().idproduct] === undefined) {
                this.items['i' + item.getProduct().idproduct] = item;
            } else {
                const i: Item = this.items['i' + item.getProduct().idproduct];

                i.setQty(item.getQty());
            }

            const nCart = Object.keys(this.items).length;

            this.cb.change(nCart);
            this.storage.setItem('items', this.items).subscribe(() => {

                this.storage.getItem('items').subscribe((item: any) => {

                    console.log(JSON.stringify(this.items));

                });
            });
            const keys = Object.keys(this.items);



            let arr = [];

            let _item = this.items;
            keys.forEach(function (ix) {
                _item[ix].product.galery='';
              _item[ix].product.specification='';
                arr.push(_item[ix]);
            });




            let it = btoa(JSON.stringify(arr));

            this.storage.getItem('user').subscribe((user: User) => {

                this.storage.getItem('customer').subscribe((customer: any) => {

                    this.http.setCart(user, customer.idcustomer, it)
                        .subscribe((res: any) => {
                            //this.items = JSON.parse(atob(res.result));

                        });

                });

            });
            // Notificamos
           


        }
    }

    setItems(itemfrom: Item[]) {
        console.log("set 2");
        if (itemfrom !== null || itemfrom !== undefined) {
            this.storage.getItem('items').subscribe((items: Item[]) => {
                this.items = items;
                if (this.items === null || this.items === undefined) {
                    this.items = [];
                }


                for (let ix = 0; ix < itemfrom.length; ix++) {
                    const item: Item = new Item();
                    item.setQty(itemfrom[ix].qty);
                    item.setProduct(itemfrom[ix].product);

                    if (this.items['i' + item.getProduct().idproduct] === undefined) {
                        this.items['i' + item.getProduct().idproduct] = item;
                        console.log('i' + item.getProduct().idproduct);
                    } else {
                        console.log(this.items['i' + item.getProduct().idproduct]);

                        this.items['i' + item.getProduct().idproduct].qty = parseInt(this.items['i' + item.getProduct().idproduct].qty) + parseInt(item.qty + '');
                    }
                }


                const nCart = Object.keys(this.items).length;

                this.cb.change(nCart);

                this.storage.setItem('items', this.items).subscribe(() => { });
                console.log("paso cart");
                console.log(this.items);
                let it = "it";// btoa(JSON.stringify(this.items));
                this.storage.getItem('user').subscribe((user: User) => {

                    this.storage.getItem('customer').subscribe((customer: any) => {

                        this.http.setCart(user, customer.idcustomer, it)
                            .subscribe((res: any) => {
                                //this.items = JSON.parse(atob(res.result));

                            });

                    });

                });


                alert("Your product was added to cart");



            });
        }
    }

    setItemsTools(itemfrom: Item[]) {

        if (itemfrom !== null || itemfrom !== undefined) {
            this.storage.getItem('items').subscribe((items: Item[]) => {
                this.items = items;
                console.log(this.items);
                if (this.items === null || this.items === undefined || this.items.length===0) {
                    this.items = [];

                    for (let ix = 0; ix < itemfrom.length; ix++) {
                        const item: Item = new Item();
                        item.setQty(itemfrom[ix].qty);
                        item.setProduct(itemfrom[ix].product);

                        this.items['i' + item.getProduct().idproduct] = item;
                    }

                    const nCart = Object.keys(this.items).length;
    
    
    
    
    
                        const keys = Object.keys(this.items);
    
    
                        this.cb.change(nCart);
    
                        this.storage.setItem('items', this.items).subscribe(() => {
    
    
                        });
    
                        let arr = [];
    
                        let _item = this.items;
                        keys.forEach(function (ix) {
                            arr.push(_item[ix]);
                        });
    
    
    
    
                        let it = btoa(JSON.stringify(arr));
    
                        this.storage.getItem('user').subscribe((user: User) => {
    
                            this.storage.getItem('customer').subscribe((customer: any) => {
    
                                this.http.setCart(user, customer.idcustomer, it)
                                    .subscribe((res: any) => {
                                        //this.items = JSON.parse(atob(res.result));
    
                                    });
    
                            });
    
                        });
    
    
    
                        alert("Your product was added to cart");
                }else{
                    if (confirm("You cart is not empty, Do you want sum qty?") == true) {
                        for (let ix = 0; ix < itemfrom.length; ix++) {
                            const item: Item = new Item();
                            item.setQty(itemfrom[ix].qty);
                            item.setProduct(itemfrom[ix].product);
    
                            if (this.items['i' + item.getProduct().idproduct] === undefined) {
                                this.items['i' + item.getProduct().idproduct] = item;
                                console.log('i' + item.getProduct().idproduct);
                            } else {
                                console.log(this.items['i' + item.getProduct().idproduct]);
    
                                this.items['i' + item.getProduct().idproduct].qty = parseInt(this.items['i' + item.getProduct().idproduct].qty) + parseInt(item.qty + '');
                            }
                        }
    
    
                        const nCart = Object.keys(this.items).length;
    
    
    
    
    
                        const keys = Object.keys(this.items);
    
    
                        this.cb.change(nCart);
    
                        this.storage.setItem('items', this.items).subscribe(() => {
    
    
                        });
    
                        let arr = [];
    
                        let _item = this.items;
                        keys.forEach(function (ix) {
                            arr.push(_item[ix]);
                        });
    
    
    
    
                        let it = btoa(JSON.stringify(arr));
    
                        this.storage.getItem('user').subscribe((user: User) => {
    
                            this.storage.getItem('customer').subscribe((customer: any) => {
    
                                this.http.setCart(user, customer.idcustomer, it)
                                    .subscribe((res: any) => {
                                        //this.items = JSON.parse(atob(res.result));
    
                                    });
    
                            });
    
                        });
    
    
    
                        alert("Your product was added to cart");
                    }

                }

                



            });
        }
    }



    private getFromStorage() {
        this.storage.getItem('items').subscribe((items: Item[]) => {
            this.items = items;
            console.log(this.items);
            if (this.items === null || this.items === undefined) {

                this.storage.getItem('user').subscribe((user: User) => {

                    if(user !== null && user !== undefined){
                        this.storage.getItem('customer').subscribe((customer: any) => {
                            if (customer !== null && customer !== undefined) {
                                this.http.getCart(user, customer.idcustomer)
                                    .subscribe((res: any) => {
                                        if (res.result !== null && res.result !== undefined) {
                                            this.items = JSON.parse(atob(res.result));
    
                                        }else{
                                            this.items = [];
                            
                                        }   
    
                                    });    
                            }else{
                                this.items = [];
                
                            }
                        });
                    }else{
                        this.items = [];
        
                    }
                });                
            }else{
                this.items = [];

            }
        });

    }

    setCb(cb: CartbadgeService) {
        this.cb = cb;
    }
}
